<template>
  <div class="section">
    <div class="bg-video">
      <video
        src="@/assets/video/career.mp4"
        ref="video"
        autoplay
        muted
        loop
        preload
        playsinline
        data-autoplay
        data-keepplaying
      ></video>
    </div>
    <div class="main">
      <Header />
      <MobileHeader />
      <div class="content">
        <h1>Careers</h1>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import MobileHeader from '@/components/MobileHeader.vue';

export default {
  components: {
    Header,
    MobileHeader,
  },
};
</script>

<style lang="sass" scoped>
@import "@/styles/_mixins.sass"
@include block0()
.section
  .main
    .content
      h1
        width: 1520px
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .section
    .main
      .content
        h1
          width: 1000px
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    .main
      height: 100vh
      .content
        margin-top: 0px
        height: calc(100% - 131px)
        justify-content: center
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
</style>
