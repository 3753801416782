<template>
  <div class="section">
    <Modal />
    <Header class="opaque" :opaque="true"/>
    <div class="content">
      <Breadcrumb :items="breadcrumbItems" />
      <div class="container">
        <div class="left-menu">
          <div
            v-for="(item, index) in titles"
            :key="index"
            class="list-item"
            :class="index === activeTitle ? 'active' : ''"
            @click="setActive(index)"
          >
            <span>
              {{ item }}
            </span>
          </div>
        </div>
        <div class="right-view">
          <h2>{{ titles[activeTitle] }}</h2>
          <h6>THE BELOW DISCLOSURE AND AUTHORIZATION LANGUAGE IS FOR MANDATORY USE BY ALL ACCOUNT
            HOLDERS</h6>
          <p>In connection with your application for employment with Allstate Cargo
            (“Prospective Employer”), Prospective Employer, its employees, agents or contractors
            may obtain one or more reports regarding your driving, and safety inspection history
            from the Federal Motor Carrier Safety Administration (FMCSA).</p>
          <br>
          <p>When the application for employment is submitted in person, if the Prospective
            Employer uses any information it obtains from FMCSA in a decision to not hire you or
            to make any other adverse employment decision regarding you, the Prospective Employer
            will provide you with a copy of the report upon which its decision was based and a
            written summary of your rights under the Fair Credit Reporting Act before taking any
            final adverse action. If any final adverse action is taken against you based upon your
            driving history or safety report, the Prospective Employer will notify you that the
            action has been taken and that the action was based in part or in whole on this
            report.</p>
          <form @submit.prevent="submit">
            <input
              type="text"
              autocomplete="name"
              placeholder="Name"
              required
              v-model="name"
            >
            <input
              type="email"
              autocomplete="email"
              placeholder="Email"
              required
              v-model="email"
            >
            <input
              type="tel"
              autocomplete="tel"
              placeholder="Number"
              required
              v-model="number"
            >
            <input
              type="text"
              autocomplete="organization"
              placeholder="Company"
              required
              v-model="company"
            >
            <input
              type="text"
              autocomplete="street-address"
              placeholder="Address"
              required
              v-model="address"
            >
            <Button type="submit">SEND</Button>
          </form>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Footer from '@/components/Footer.vue';
import Button from '@/components/Button.vue';
import axios from 'axios';
import Modal from '@/components/Modal.vue';

export default {
  components: {
    Header,
    Breadcrumb,
    Footer,
    Button,
    Modal,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          title: 'Home',
          to: '/',
        },
        {
          title: 'Careers',
          to: '/careers',
        },
      ],
      activeTitle: 0,
      titles: [
        'Authorization Form',
        'Drivers',
        'Owner Operators',
        'Safety Department',
        'Dispatchers',
        'Accounting',
        'Mechanics',
      ],
      name: '',
      email: '',
      number: '',
      company: '',
      address: '',
    };
  },
  methods: {
    async submit() {
      const {
        name, email, number, company, address,
      } = this;
      if (name && email && number && company && address) {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('number', number);
        formData.append('company', company);
        formData.append('address', address);
        try {
          const res = await axios.post('/contacts', formData);
          if (res.status === 200) {
            this.showModal();
          }
        } catch (e) {
          console.log('Error occured during form submit');
          console.error(e);
        }
      }
    },
    showModal() {
      document.getElementById('modalContainer').style.display = 'flex';
    },
    setActive(index) {
      this.activeTitle = index;
    },
  },
};
</script>

<style lang="sass" scoped>
.section
  position: relative
  .content
    height: calc(100% - 128px - 170px)
    width: 1520px
    margin: 0px auto
    padding-top: 84px
    .container
      margin-top: 54px
      display: flex
      .left-menu
        margin-right: 46px
        .list-item
          background-color: #ececec
          padding: 12px
          margin-bottom: 21px
          width: 416px
          cursor: pointer
          &:last-child
            margin-bottom: 0px
          &.active
            background-color: #ffffff
            border: 2px solid var(--accent-color)
          span
            font-weight: 500
            font-size: 18px
            line-height: 23px
            text-align: center
            color: #262626
      .right-view
        h6
          font-weight: 400
          font-size: 11px
          line-height: 19px
          color: #acacac
          margin: 23px 0px
        p
          line-height: 24px
          color: #262626
          width: 1003px
        form
          margin-top: 40px
          display: grid
          grid-template-columns: 1fr 1fr 1fr
          grid-template-rows: 1fr 1fr
          column-gap: 20px
          row-gap: 17px
          width: 790px
          input
            border: 2px solid #f0f0f0
            font-size: 14px
            color: #000000
            padding: 8px 18px
            width: 250px
            &::placeholder
              font-size: 14px
              color: #acacac
          button
            width: 250px
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .section
    position: relative
    .content
      height: calc(100% - 80px - 120px)
      width: 1320px
      padding-top: 40px
      .container
        margin-top: 40px
        .left-menu
          .list-item
            padding: 8px
            margin-bottom: 16px
            width: 280px
            span
              font-size: 16px
              line-height: 21px
        .right-view
          h6
            font-size: 11px
            line-height: 19px
            margin: 15px 0px
          p
            width: auto
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    background-color: #fff
    position: relative
    .content
      height: auto
      width: 100%
      max-width: 800px
      margin: 0px auto
      padding: 40px 40px 80px 40px
      .container
        display: flex
        flex-direction: column-reverse
        .left-menu
          margin-right: 0px
          margin-top: 40px
          margin-bottom: 40px
          .list-item
            padding: 12px
            margin-bottom: 21px
            width: 100%
            max-width: 800px
            span
              font-size: 22px
              line-height: 37px
        .right-view
          margin-bottom: 40px
          h6
            font-size: 20px
            line-height: 34px
            margin: 64px 0px 40px 0px
          p
            font-size: 20px
            line-height: 34px
            width: 100%
          form
            margin-top: 40px
            grid-template-columns: 1fr
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr
            column-gap: 20px
            row-gap: 17px
            width: 100%
            input
              font-size: 20px
              line-height: 34px
              padding: 8px 18px
              width: 100%
              max-width: 800px
              text-align: center
              &::placeholder
                text-align: center
                font-size: 20px
                line-height: 34px
            button
              justify-self: center
              width: 100%
              max-width: 306px

//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .section
    background-color: #fff
    position: relative
    .content
      height: auto
      width: 100%
      margin: 0px auto
      padding: 40px 15px 0px 15px
      .container
        display: flex
        flex-direction: column-reverse
        .left-menu
          margin-right: 0px
          margin-top: 40px
          margin-bottom: 40px
          .list-item
            padding: 12px
            margin-bottom: 21px
            width: 100%
            max-width: 300px
            span
              text-align: center
        .right-view
          h6
            margin: 23px 0px
          p
            width: 100%
          form
            margin-top: 40px
            grid-template-columns: 1fr
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr
            column-gap: 20px
            row-gap: 17px
            width: 100%
            input
              font-size: 14px
              padding: 8px 18px
              width: 100%
              max-width: 300px
              &::placeholder
                font-size: 14px
            button
              width: 100%
              max-width: 300px
</style>
